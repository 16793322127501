// Add a global Promise polyfill so that we can dynamically import the other polyfills if needed:
import "promise-polyfill/src/polyfill";

const browserSupportsAllFeatures =
  window.Promise &&
  window.fetch &&
  window.Symbol &&
  window.IntersectionObserver &&
  window.URLSearchParams;

function loadPolyfills(done) {
  const polyfills = [];
  polyfills.push(
    import(/* webpackChunkName: "polyfills" */ "react-app-polyfill/ie11")
  );
  polyfills.push(
    import(/* webpackChunkName: "polyfills" */ "react-app-polyfill/stable")
  );
  polyfills.push(
    import(/* webpackChunkName: "polyfills" */ "intersection-observer")
  );
  polyfills.push(import(/* webpackChunkName: "polyfills" */ "core-js/web"));
  Promise.all(polyfills)
    .then(done)
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error("Failed to load polyfills", error);
      throw error;
    });
}

export { loadPolyfills, browserSupportsAllFeatures };
