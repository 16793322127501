// Because polyfills have to be loaded before any external dependencies, this
// entrypoint conditionally loads polyfills and calls an async-imported
// render script.
import { loadPolyfills, browserSupportsAllFeatures } from "./polyfills";

// Callback to import the render function to bootstrap the application.
const render = () => import("./render").then(({ default: render }) => render());

// Check for browser support and load polyfills if necessary
if (browserSupportsAllFeatures) {
  // Browsers that support all features run `render()` immediately.
  render();
} else {
  // All other browsers load polyfills and then run `render()`.
  loadPolyfills(render);
}
